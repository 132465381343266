<template>
  <!-- <div class="text-center"> -->
  <v-dialog v-model="dialog" fullscreen @click:outside="clickOutside">
    <template v-slot:activator="{ on, attrs }">
      <p id="archiveCategoryImport" v-bind="attrs" v-on="on"></p>
    </template>
    <div
      v-if="permission.read_perm === 1"
      style="overflow:hidden;border:1px solid black;"
    >
      <!-- <v-container style="background: white; border-radius: 5px;"> -->
      <v-form
        :disabled="permission.create_perm == 0"
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative; background:white;min-height:100vh;"
      >
        <v-card
          flat
          style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
        >
          <div
            style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 20px; margin: 0; font-weight: bold">
                Pengelolaan Dokumen (Import)
              </p>
            </v-toolbar-title>
          </div>
          <div>
            <v-btn
              small
              tile
              color="black"
              class="ma-2"
              style="color: white"
              @click="close"
            >
              Batal
            </v-btn>
          </div>
        </v-card>
        <!-- <v-row style="padding: 15px">
          </v-row> -->

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:99%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn class="mx-2 elevation-4" dark small color="cyan">
                  <v-icon small dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Please download tempalate for upload
                <a href="" target="__blank">here</a>
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <p style="border-top:5px solid cyan;"></p>
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <!-- a -->
                  <v-btn
                    tile
                    small
                    color="grey"
                    class="ma-2 white--text"
                    @click="archiveCategoryImport"
                    :disabled="uploadFile === null"
                    :loading="loading"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:99%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="importData"
                class="elevation-1 mx-auto row-pointer"
                style="width:99%; margin-top: 20px; margin-bottom:20px;"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                  >
                    <v-btn class="mx-2 elevation-4" dark small color="cyan">
                      <v-icon small dark>
                        mdi-clipboard-list-outline
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>List Import Arsip</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.document_type_id`]="{ item }">
                  <div
                    v-if="item.document_type_id === '3'"
                    style="padding: 0; display: flex; flex-direction: row"
                  >
                    {{ 'SOP' }}
                  </div>
                  <div
                    v-if="item.document_type_id === '2'"
                    style="padding: 0; display: flex; flex-direction: row"
                  >
                    {{ 'SCP' }}
                  </div>
                  <div
                    v-if="item.document_type_id === '1'"
                    style="padding: 0; display: flex; flex-direction: row"
                  >
                    {{ 'HCP' }}
                  </div>
                </template>
              </v-data-table>
              <v-col
                v-if="permission.create_perm == 1"
                cols="12"
                style="padding:0 5px;"
              >
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveArchiveCategoryImport"
                  :loading="loading"
                >
                  Save Data
                </v-btn>
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="pink"
                  height="30px"
                  width="100px"
                  class="white--text"
                  :disabled="!isImport"
                  @click="remove"
                  :loading="loading"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <!-- </v-container> -->
    </div>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  name: 'Archive-Form',
  props: ['param', 'permission'],
  data() {
    return {
      eSanqua: buildType.apiURL('esanqua'),
      upload: buildType.apiURL('upload2'),
      download: buildType.apiURL('download'),
      dialog: false,
      form: {},
      rules: {},
      loading: false,
      select: null,
      search: null,
      items: [],
      new: [],

      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,

      headers: [
        {
          text: 'No. Dokumen',
          value: 'doc_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama Dokumen',
          value: 'name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tipe',
          value: 'document_type_id',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kepemilikan',
          value: 'plant_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'pic_department_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl Terbit',
          value: 'doc_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl Expired',
          value: 'doc_expiry_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'doc_status',
          align: 'left',
          sortable: false
        }
      ],
      importData: []
    }
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.uploadFile = null
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.dialog = false
      this.clear()
    },
    // filterDate(date) {
    //   const day = date.slice(0, date.indexOf('-'))
    //   const month = date.slice(date.indexOf('-') + 1, date.lastIndexOf('-'))
    //   const year = date.slice(date.lastIndexOf('-') + 1)
    //   return year + '-' + month + '-' + day
    // },
    remove() {
      this.importData = []
      this.isImport = false
    },
    archiveCategoryImport() {
      this.importData = []
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const data = new FormData()
        data.append('file', this.uploadFile)
        console.log(this.uploadFile)
        axios
          .post(
            `${this.eSanqua}archive_document/archive/documentcategory/upload`,
            data
          )
          .then(res => {
            console.log(res)
            if (res.data.status_code === '00') {
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].name !== '') {
                  const param = {
                    description: res.data.data[i].description,
                    doc_date: res.data.data[i].doc_date,
                    doc_expiry_date: res.data.data[i].doc_expiry_date,
                    doc_no: res.data.data[i].doc_no,
                    doc_qty: res.data.data[i].doc_qty,
                    doc_qty_unit: res.data.data[i].doc_qty_unit,
                    doc_qty_unit_id: res.data.data[i].doc_qty_unit_id,
                    doc_status: res.data.data[i].doc_status,
                    doc_status_id: res.data.data[i].doc_status_id,
                    document_type_id: res.data.data[i].document_type_id,
                    has_expire: res.data.data[i].has_expire,
                    plant_id: res.data.data[i].plant_id,
                    plant_name: res.data.data[i].plant_name,
                    location_department_name:
                      res.data.data[i].location_department_name,
                    name: res.data.data[i].name,
                    pic_department_id: res.data.data[i].pic_department_id,
                    pic_department_name: res.data.data[i].pic_department_name,
                    published_by: res.data.data[i].published_by,
                    published_city: res.data.data[i].published_city,
                    rack_index: res.data.data[i].rack_index,
                    category_id: res.data.data[i].category_id
                  }
                  if (res.data.data[i].id !== undefined) {
                    Object.assign(param, {
                      id: res.data.data[i].id
                    })
                  }
                  // this.importData.push(param)
                }
              }
              this.uploadFile = null
              this.isImport = true
            } else {
              this.showMsgDialog('error', res.data)
              this.importData = []
              this.isImport = false
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.importData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx', false)
        this.clear()
      }
    },
    saveArchiveCategoryImport() {
      this.loading = true
      const form = {
        act: 'add',
        data: this.importData
      }
      console.log(form)
      axios
        .post(
          `${this.eSanqua}archive_document/archive/documentcategory/batch_save`,
          form
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.importData = []
            this.isImport = false
          }
          this.loading = false
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          this.importData = []
          this.isImport = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.arch-add-new-btn {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
</style>
